var VCMSSearchPage = (function()
{
    var SearchResults = {
        data : [],
        count : 0,
        keyword: '' };

    var PageIndex = 1;
    var PageSize = 5;

    var PreloadedKeyword = '{{preload_keyword}}';

    var app;

    function  OnSearchButtonClicked (e) {
        e.preventDefault();
        PageIndex = 0;
        // var url = "{{ path('get_article_search') }}";
        var formSerialize = $('#vcmsbundle_search').serialize();
        SearchArticles(formSerialize);

        return false;
    }

    var OnSearchRequestFail = function (thrownError, xhr)
    {
        console.log("Search Failed");
        console.log(thrownError);
    }

    var OnSearchRequestSuccess = function(result)
    {
        SearchResults = result;    
        listArticles();
    }

    var SearchArticles = function(frmData)
    {
        // var url = "{{ path('get_article_search') }}";
        var url = Routing.generate('get_article_search');

        $.ajax({ url: url,
            data: frmData,
            success: function (result) {
                OnSearchRequestSuccess(result);
            },
            error:   function (xhr, ajaxOptions, thrownError) {
                OnSearchRequestFail(thrownError, xhr);
                },

            type: "GET",
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader("X-Auth", 'Bearer '+ vcmsjwt);
                xhr.setRequestHeader("Authorization", vcmshba);
            }
        });    
    }

    function listArticles()
    {
        var a, item, alist;
        alist = $('#alist');

        var StartIndex = PageIndex * PageSize;
        var MaxCount = StartIndex + PageSize;

        if (MaxCount > SearchResults.count)
        MaxCount = SearchResults.count; 

        if ( (SearchResults.count === undefined) || (SearchResults.count == 0) )
        {
            $("#record_count").html("We did not find any records matching your search keywords ");
        }
        else
            $("#record_count").html("Showing "+ ( (PageIndex*PageSize)+1) + " to " + MaxCount + " from a total of " + SearchResults.count + " Records" );

        alist.empty();

        for (var i = StartIndex; i <  MaxCount; i++)
        {
            a = SearchResults.data[i];
            item = GetArticleItem(false, false, a, SearchResults.keyword);
            alist.append(item);
        }
    }

    function GetArticleItem(show_vol, show_author, article, skeyword)
    {
        var ahref = Routing.generate('articles', {articleid: article.articleid, kw : skeyword} );
        var itemHtml = '<li><a class="navlink link_nolines vcms_atitle" href="'+ahref+'"> '+ article.atitle+' </a><br>'
            + '<span class="vcms_subtitle">'+ article.subtitle +'</span> ';

        if (show_vol)
        {
            itemHtml = itemHtml + '<div><a class="vcms_content_jlink"></a> </div>';
        }

        itemHtml = itemHtml + "</li>";
        return itemHtml;
    }

    var previousPage = function()
    {
        if (SearchResults.count == 0) return;

        if (PageIndex>0)
        {
            PageIndex--;
            listArticles();
        }
    }

    var nextPage = function()
    {
        if (SearchResults.count == 0) return;

        if ( (PageIndex*PageSize)+PageSize < SearchResults.count )
        {
            PageIndex++;
            listArticles();
        }
    }

    var InitSiteSearch = function (PreloadedKeyword)
    {
        $('#vcmsbundle_search').submit(function(e){
            OnSearchButtonClicked(e);
        });

        $('#btn-previous').click(function(e){
            e.preventDefault();
            previousPage();
        });

        $('#btn-next').click(function(e){
            e.preventDefault();
            nextPage();
        });
        
        if (PreloadedKeyword!='')
        {
            $('#vcmsbundle_search_keyword').val(PreloadedKeyword);
            var frm = $('#vcmsbundle_search');
            PageIndex = 1;
            var formSerialize = $(frm).serialize();
            SearchArticles(formSerialize);
        }
    };

    function getData()
    {
        return {};
    }

    var render = function() {
        app = new Vue({
            el: '#vcms_block_events',
            data: getData(),
            template : eventBlockTemplate       
          })

        return app;
    }


    var HandleSearchBox = function(e)
    {
            var kw = $('#search_keyword').val();
            var durl = Routing.generate('search', {'searchkey': kw} );
            window.location = durl;
            return false;
    }

    var onSearchBoxKey = function(e)
    {
        if (e.keyCode == 13)
        {
            HandleSearchBox();
        }
    }

    var configSearchBox = function()
    {
        // onkeypress="return onSearchBoxKey(event)" 
        // onclick="HandleSearchBox(); return false;" 
        console.log("configSearchButton Called");

        $('#search_keyword').keypress(function(e){
                onSearchBoxKey(e);
                return true;
        });

        $('#search_fabtn').click(function(e){
                HandleSearchBox();
                return false;
        });
    }

    var SetHandlers = function(a, b)
    {
        OnSearchRequestSuccess = a;
        OnSearchRequestFail = b;
    }

    return {
        render : render,
        InitSiteSearch : InitSiteSearch,
        previousPage : previousPage,
        nextPage : nextPage,
        HandleSearchBox : HandleSearchBox,
        onSearchBoxKey : onSearchBoxKey,
        configSearchBox : configSearchBox,


        SearchArticles : SearchArticles,
        SetHandlers : SetHandlers,
    }

});


export default VCMSSearchPage;
