
import videojs from 'video.js';

var CMSVideoPlayer = (function(){

    var player = null;


    var InitPlayer = function(el){
        var options = {
                width: 800,
                height: 400,
                controls: true,
        };

        player = videojs('my-player', options, function onPlayerReady() {
            videojs.log('Your player is ready!');
            // In this context, `this` is the player that was created by Video.js.
            this.play();

            // How about an event listener?
            this.on('ended', function() {
                videojs.log('Awww...over so soon?!');
            });
        });
    }

    var LaunchPlayer = function(){
        let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
        width=0,height=0,left=-1000,top=-1000`;

        var vidwin = window.open("/video/1" );
    }

    return {
        InitPlayer : InitPlayer,
        LaunchPlayer: LaunchPlayer,
    }
});


export default CMSVideoPlayer;



