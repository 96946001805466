var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row", attrs: { id: "slider_row" } }, [
        _c(
          "div",
          { staticClass: "col-md-4", staticStyle: { "text-align": "center" } },
          [
            _c(
              "a",
              { staticClass: "fpjournal_link", attrs: { href: _vm.jlink } },
              [
                _c("img", {
                  staticClass: "fp_journal_img",
                  attrs: { src: _vm.JournalData.titleimage_path },
                }),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "1em" } }, [
              _c(
                "a",
                { staticClass: "fp_j_listitem", attrs: { href: _vm.jlink } },
                [_vm._v(" Full Table of Contents  ")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "no-wide-screen fp_box_title",
                staticStyle: { "text-align": "left" },
              },
              [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "padding-top": "1em",
                      "padding-right": ".5em",
                      display: "block",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "fpjournal_link",
                        staticStyle: { "font-size": "1.2em" },
                        attrs: { href: _vm.jlink },
                      },
                      [_vm._v(" " + _vm._s(_vm.JournalData.editiontitle) + " ")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v(" " + _vm._s(_vm.VolStr)),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-md-6 no-small-screen fp_box_title" }, [
          _c("div", { staticStyle: { "padding-left": "11em" } }, [
            _c("div", [
              _c(
                "span",
                {
                  staticStyle: {
                    "padding-top": "1em",
                    "padding-right": ".5em",
                    display: "block",
                    "text-align": "left",
                    height: "6em",
                  },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "fpjournal_link",
                      staticStyle: { "font-size": "1.5em" },
                      attrs: { href: _vm.jlink },
                    },
                    [_vm._v(" " + _vm._s(_vm.JournalData.editiontitle) + " ")]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-weight": "bold" } }, [
                _vm._v(" " + _vm._s(_vm.VolStr) + " "),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticStyle: { "margin-top": "2em" } }, [
              _c(
                "ul",
                {
                  staticStyle: { "padding-left": ".5em", "margin-top": "1em" },
                },
                _vm._l(_vm.JournalData.slide_articles, function (jarticle) {
                  return _c(
                    "li",
                    { key: jarticle.articleid, staticClass: "fp_j_listitem" },
                    [
                      _c("span", { staticClass: "fa fa-angle-right" }),
                      _vm._v(" "),
                      _c("a", { attrs: { href: _vm.getSeoLink(jarticle) } }, [
                        _vm._v("  " + _vm._s(jarticle.atitle) + " "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "ul",
                        { staticStyle: { padding: "0" } },
                        _vm._l(jarticle.authors, function (jaa) {
                          return _c(
                            "li",
                            {
                              key: jaa.authid,
                              staticStyle: {
                                "list-style": "none",
                                display: "inline",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": ".9em" } },
                                [_vm._v(" " + _vm._s(jaa.prefix) + " ")]
                              ),
                              _vm._v(" "),
                              _c("span", { staticClass: "slider_authname" }, [
                                _vm._v(
                                  "  \n                                        " +
                                    _vm._s(jaa.authname) +
                                    " \n                                        "
                                ),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }