
var CmsCart = (function(cartName)
{
    var CartName = cartName;    
    var CartData  = null;

    var GetCartItems =  function()
    {
        var mockItems = [
            { itemid : 1, productid : 1, desc : 'product 1', qty : '1',  price: '10', currency : 'USD' },
            { itemid : 2, productid : 2, desc : 'product 2', qty : '1',  price: '20', currency : 'USD' },
            { itemid : 3, productid : 3, desc : 'product 3', qty : '1',  price: '30', currency : 'USD' },
            { itemid : 4, productid : 4, desc : 'product 4', qty : '1',  price: '40', currency : 'USD' },
            { itemid : 5, productid : 5, desc : 'product 5', qty : '1',  price: '50', currency : 'USD' },
        ]
        return mockItems;
    }

    var ReCalculate= function ()
    {
        // renumber the items in the cart as some are added or deleted. 
    }

    var AddItem = function (item)
    {
        CartData.items.push(item);
        SaveCart();
    }
    var DelItem = function (item_id)
    {
        CartData.items = $.grep(CartData.items, function(e){ 
            return e.itemid != item_id;
        });       
       SaveCart();
    }

    var ResetCart = function() {
        var emptyCart = {
            username : "no-user!",
            last_updated : new Date(),
            items : GetCartItems(),           
        }
        CartData = emptyCart;

        SaveCart();
    }

    var LoadCart = function ()
    {
        var cartObj = localStorage.getItem(CartName);
        if ( (cartObj == null) || (typeof(cartObj) == 'undefined' )||(cartObj =='undefined'))
        {
            var emptyCart = {
                username : "no-user!",
                last_updated : new Date(),
                items : GetCartItems(),           
            }

            CartData = emptyCart;
        }
        else {
            CartData = JSON.parse(cartObj);
        }

        return CartData;
    }

    var GetItems = function()
    {
        return CartData.items;
    }

    var SaveCart = function(){
        localStorage.setItem(CartName, JSON.stringify(CartData) );
    }

    var DeleteCart = function(){
        localStorage.setItem(CartName, null);
    }


    return {                
        LoadCart : LoadCart, 
        SaveCart : SaveCart,
        ResetCart : ResetCart,
        DeleteCart : DeleteCart,
        AddItem : AddItem,
        DelItem : DelItem,
        GetItems : GetItems,
    }

});


export default CmsCart;