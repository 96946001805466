<script>

import Vue from 'vue'

import CmsStore from '../../js/shop/vcmsstore.js';

// import jtoc from '../../content/journal/10.json';

var VcmsShopCart = Vue.component("vcms-shop-cart",{

    data: function()
    {
        return {
           page_title: 'VcmsShopCard ',
           show : true,
           form : { 
                keyword : null
           },
           submitted : false,
           perPage : 5,
           currentPage: 1,
           fields: [{
                        key: 'itemid',
                        label: 'Item #',
                        thClass : 'th_numeric',
                        tdClass : 'th_numeric',
                        sortable: true
                    },
                    {
                        key: 'productid',
                        label: 'Product ID',
                        thClass : 'th_numeric',
                        tdClass : 'th_numeric',
                        sortable: true
                    },
                    {
                        key: 'desc',
                        label: 'Description',
                        thClass : 'th_desc',
                        tdClass : 'th_desc',
                        sortable: true
                    },{
                        key: 'qty',
                        label: 'Quantity',
                        thClass : 'th_numeric',
                        tdClass : 'th_numeric',
                        sortable: true
                    },                    
                    {
                        key: 'price',
                        label: 'Item Price',
                        thClass : 'th_numeric',
                        tdClass : 'th_numeric',
                        sortable: true
                    },
                    {
                        key: 'item_total',
                        label: 'Item Total',
                        thClass : 'th_numeric',
                        tdClass : 'th_numeric',
                        sortable: true
                    },
                    {
                        key: 'delItemBtn',
                        label: '',
                    }
           ],
           items: [],
            //     { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            //     { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            //     { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
            //     { age: 38, first_name: 'Jami', last_name: 'Carney' }
            // ],
            search_result : new Array(), // jtoc.book.toc,
            storeApi : new CmsStore(),
            Cart : null,
        }
    },
    computed: {

        rows() {
            return this.items.length
        },

        perPageText()
        {
            return 'Results Per Page ' + this.perPage;
        },

        countResults() 
        {
            return 'Query returned ' + this.items.length + " Results.";
        },
        sortByField(){
            return 'itemid';
        },
        showResults()
        {
            return ( this.items.length > 0 );
        }
    },

    mounted: function () {

            this.Cart = this.storeApi.GetCart();
            this.Cart.ResetCart();

            this.loadTestData();
    },

    methods: {
        removeItem: function(data)
        {
            console.log("deleting item id = " + data.item.itemid + " desc: " + data.item.desc )
                        this.items.length = 0;
            var alist = this.items;

            this.Cart.DelItem(data.item.itemid);
            var cartItems = this.Cart.GetItems();

            this.addItems( alist, cartItems );

        },
        incItemQty : function (data){
            data.item.qty++;
        },
        decItemQty : function (data){
            data.item.qty--;
        },
        calcItemTotal : function(data) { 
                return ( data.item.price * data.item.qty );
        },
        addItems : function(alist, slist){
            slist.forEach(function(a){

                var rec = {
                    itemid : a.itemid,
                    productid : a.productid,
                    desc : a.desc,
                    qty  : a.qty,
                    price  : a.price,
                    currency  : a.currency,
                };
                // console.log("added: " + a.atitle);
                alist.push(rec);
            });
        },

        loadTestData : function()
        {
            this.items.length = 0;
            var alist = this.items;
            
            var CartData = this.Cart.LoadCart();
            
            var cartItems = CartData.items;

            this.addItems( alist, cartItems );
        },

        onSubmit: function(e)
        {
            console.log("Searching for: " + this.form.keyword);
            e.preventDefault();

            // this.storeApi.LoadUserItems({ 'vcmsbundle_search' : this.form });

            this.submitted = true;

            return false;
        },

        onReset: function(){

        },
        OnSearchRequestFail : function (thrownError, xhr)
        {
            console.log("Search Failed");
            console.log(thrownError);
        },
        OnSearchRequestSuccess : function(result)
        {
            console.log("Search Success " + result.length + " records. ");
            
            this.items.length = 0;
            var alist = this.items;
            this.addItems( alist, result.data);
        }

    }

});

export default VcmsShopCart;

</script>


<template>
<div>

<div class="search_app_title">  Shopping Cart </div>
<b-form @submit="onSubmit" @reset="onReset" v-if="show" inline class="search_form_class">

    <b-container fluid>
        <b-row>
            <b-col v-if="items.length > perPage ">
                    <b-form-group  
                            id="input-group-2"
                            label="Results Per Page:"
                            label-for="input-keyword"
                            description=""
                            style="width:10em;"
                        >                        
                        <b-form-select 
                                id="select-page-size"
                                style="width:5em;"
                                v-model="perPage"
                                :options="[5, 10, 15, 20]"
                                :text="perPageText" class="m-md-2">
                            
                        </b-form-select>
                    </b-form-group>
            </b-col>    
        </b-row>    
    </b-container>

</b-form>

<b-container style="margin-left:1em;">

    <!-- <div class="mt-3"> Shopping Cart Contents {{ currentPage }}</div> -->

    <b-row v-if=" (items.length > perPage) ">
        <b-col>

             <div class="overflow-auto">
                <b-pagination 
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
              </div>  
        </b-col>
    </b-row>

    <b-row v-if="showResults">
        <b-col>
                <b-table class="results_table"
                    :sort-by="sortByField"
                    :sort-desc="true"
                    :items="items"
                    :fields="fields"
                    :per-page="perPage"
                    :current-page="currentPage"
                    small striped hover
                    :primary-key="sortByField"
                    >

                    <template slot="qty" slot-scope="data">
                        <span @click="decItemQty(data)" class="fa fa-minus-circle fa-lg qtybtn_class"> </span>
                        {{ data.item.qty }}
                        <span @click="incItemQty(data)" class="fa fa-plus-circle fa-lg qtybtn_class"> </span> 
                    </template>

                    <template slot="item_total" slot-scope="data">
                        <span class="itemtotal_span" > {{  calcItemTotal(data) }} </span>
                    </template>

                    
                    <template slot="delItemBtn" slot-scope="data">
                        <b-button @click="removeItem(data)" class="btntrash_class"> <span class="fa fa-trash"> </span> </b-button>
                    </template>


                </b-table>
        </b-col>
    </b-row>

    <b-row>
        <b-col cols="12">                
        </b-col>
            
    </b-row>

</b-container>


    <div v-if="showResults" class="search_app_footer">  {{  countResults }} </div>


</div>

</template>


<style>

.itemtotal_span
{
    font-weight: 500;
}

.btntrash_class {
    width: 28px;
    height: 28px;
    padding: 2px;
}

.th_numeric {
    width: 10em;
}

.th_desc {
    width: 30em;
}

.qtybtn_class
{
    border-radius: 8px;    
    padding-left: .6em;
    padding-right: .6em;
}

.search_app_title { 
    font-size: 1em;
    color : maroon;
    margin-left: 0;
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    margin-bottom: 2em;
}

.search_app_footer { 
    font-size: 1em;
    color : maroon;
    margin-left: 0;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    margin-top: 2em;
}


.results_table
{
    font-family: Roboto, sans-serif;
    font-size: .8em;
}
.search_form_class {
    font-family: Roboto, sans-serif;
    font-size: .8em;    
}
</style>
