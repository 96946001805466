var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "search_app_title" }, [
        _vm._v("  " + _vm._s(_vm.page_title) + " "),
      ]),
      _vm._v(" "),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm.show
                    ? _c(
                        "b-form",
                        {
                          staticClass: "search_form_class",
                          attrs: { inline: "" },
                          on: { submit: _vm.onSubmit, reset: _vm.onReset },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticStyle: {
                                width: "100%",
                                "margin-right": "1.5em",
                              },
                              attrs: {
                                id: "input-group-1",
                                label: "Search Keyword:",
                                "label-for": "input-keyword",
                                description: _vm.form.keyword,
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "input-keyword",
                                  type: "text",
                                  required: "",
                                  placeholder: "Enter Keywords",
                                },
                                model: {
                                  value: _vm.form.keyword,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "keyword", $$v)
                                  },
                                  expression: "form.keyword",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    size: "sm",
                                  },
                                },
                                [
                                  _c("span", { staticClass: "fa fa-search" }, [
                                    _vm._v(" Search "),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-form-select", {
                                staticClass: "m-md-2",
                                staticStyle: { width: "5em" },
                                attrs: {
                                  id: "select-page-size",
                                  options: [5, 10, 15, 20],
                                  text: _vm.perPageText,
                                },
                                model: {
                                  value: _vm.perPage,
                                  callback: function ($$v) {
                                    _vm.perPage = $$v
                                  },
                                  expression: "perPage",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.showResults
            ? _c(
                "b-row",
                [
                  _c("b-pagination", {
                    attrs: {
                      "total-rows": _vm.rows,
                      "per-page": _vm.perPage,
                      "aria-controls": "my-table",
                    },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showResults
            ? _c("b-row", [
                _c(
                  "div",
                  { staticStyle: { overflow: "auto", height: "30em" } },
                  [
                    _c("p", { staticClass: "mt-3" }, [
                      _vm._v(
                        "Article Results - Page: " + _vm._s(_vm.currentPage)
                      ),
                    ]),
                    _vm._v(" "),
                    _c("b-table", {
                      staticClass: "results_table",
                      attrs: {
                        id: "my-table",
                        "sort-by": _vm.sortByField,
                        "sort-desc": true,
                        items: _vm.items,
                        "per-page": _vm.perPage,
                        "current-page": _vm.currentPage,
                        selectable: "",
                        small: "",
                        "primary-key": _vm.sortByField,
                      },
                      on: { "row-click": _vm.onRowClick },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("b-row", [_c("b-col", { attrs: { cols: "12" } })], 1),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showResults
        ? _c("div", { staticClass: "search_app_footer" }, [
            _vm._v("  " + _vm._s(_vm.countResults) + " "),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }