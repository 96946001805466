var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-carousel",
        {
          staticStyle: {
            padding: "0px",
            "vertical-align": "top",
            "padding-top": "1em",
          },
          attrs: {
            id: "carousel-1",
            interval: 40000,
            background: "#ffabab",
            "img-height": "300px",
            fade: "",
          },
          on: {
            "sliding-start": _vm.onSlideStart,
            "sliding-end": _vm.onSlideEnd,
          },
          model: {
            value: _vm.slide,
            callback: function ($$v) {
              _vm.slide = $$v
            },
            expression: "slide",
          },
        },
        [
          _c(
            "b-carousel-slide",
            {
              staticClass: "vcms_quote_slide",
              attrs: { caption: "hannan", "img-blank": "" },
            },
            [
              _c("p", [
                _c("img", {
                  staticClass: "qvcms_image",
                  attrs: {
                    slot: "img",
                    src: "images/authimages/HanaAshrawi.jpg",
                  },
                  slot: "img",
                }),
                _vm._v(
                  ' \n        ’’The Journal provides a valuable opportunity for sustaining a process of communication essential to genuine peacemaking.” \n          -- Dr. Hanan Ashrawi, Palestinian spokeswoman " '
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }