<script>
import Vue from 'vue';

var BlogsBlock = Vue.component("vcms-blogs-block", 
{
    props : {
        BlogsData : Object,
    },

    data : function()
    {
        var blog_data = new Array();
        this.BlogsData.blogs.forEach(element => {
            try{
            var e2 = element;
            var link = '/blogs/' + element.bid;
            e2.url = link;
            e2.author_link = '/author/' + element.authors[0].authid;
            blog_data.push(e2);            
            }
            catch(err)
            {
                console.error(err);
            }
        });;

        return {
            blogs : blog_data,            
            show_title : true,
        };
    },
    methods: {     
        ShowMore : function(){
            var blogs_url = Routing.generate("blogs");
            window.open(blogs_url);
        }   
    }

});

export default BlogsBlock;

</script>

<template>

<div id="vue_blogslist">

    <div v-if="show_title">
        <div align="center" class="frontpage_section_header mt-1 mb-3"> BLOGS </div>
    </div>

    <b-card-group deck>

        <b-card class="blog_card shadow-sm pt-1 mb-2 bg-white rounded" img-left v-for="blog in blogs" v-bind:key="blog.bid"> 

            <b-card-img img-left align="left" :src="blog.authors[0].titleimage_path" class="ml-1 pl-0 blog_auth_pic" width="90" height="110" />
            <b-card-text>

                <a class="stretched-link" v-bind:href="blog.url"> {{ blog.title }} </a>
            
                <p class="mb-1" ><span> By </span> <a v-bind:href="blog.author_url"> {{ blog.authors[0].authname }} {{ blog.authors[0].authlname }} </a>
                        <span v-if="blog.authors.length==2"> 
                               and <a class="" v-bind:href="blog.author_url"> {{ blog.authors[1].authname }} {{ blog.authors[1].authlname }} </a>
                        </span>

                    
                </p>
                <span class="blog_date"> On {{ blog.cdate }} </span>
                            
            </b-card-text>
                
        </b-card>
        
    
    </b-card-group>
    
    <b-button @click="ShowMore" variant="info">
             <span class="fa fa-plus-circle"></span> Blogs Archive ...
    </b-button>

</div>


</template>


<style>


.blog_auth_pic
{		
	border-radius: 20px;
	margin-right: 1em;
	width: 100px;
    height: 125px; 
}

.blog_card
{
    font-family: Arial, Helvetica, sans-serif;
    min-width: 65%;
    height: 10em;
}


</style>
