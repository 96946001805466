"use strict";


import CmsStore from './shop/vcmsstore';

var VcmsUsers = (function ()
{
  var base_uri = Routing.generate("homepage");

  var customerApi = new CmsStore();

  var supports_html5_storage = function () {
      try {
          return 'localStorage' in window && window.localStorage !== null;
      } catch (e) {
          return false;
      }
  }

  var UpdateLocalStorageIcon = function ()
  {
    if (supports_html5_storage())
    {
      console.log("Supports Local Storage ");
      insertToolButton('<span id="localstorageicon" class="fa fa-database" style="padding-right:1em;" title="Supports Local Storage"></span>', "#lstorage", "Supports Web Storage", "return showLocalStorage();");
    }
    else
    {
      console.log("Does NOT supports Local Storage ");
    }

    $('#localstorageicon').tooltip();
  }

  var showLocalStorage = function ()
  {
    var mylist = $('<ul class="local_storage_items"> </ul>');
    var i = 0, myli;
    for (i = 0; i < localStorage.length; i+=1)
    {
      myli = '<li>' + localStorage.getItem(localStorage.key(i)) + '</li>';
      mylist.append(myli);
    }
    $('#full_site_page').html(mylist);
    return false;
  }

  var insertToolButton = function (title, link, tooltip, jsclick)
  {  
      $('#web_bc').prepend("<li><a href="+ link +'" onclick="'+jsclick+'" >'+ title +" </a></li>");
  }

  var InitProfilePic = function ()
  {
      $("#fileupload").change(function(e) {
          console.log(e.target.files)
          // grab the first image in the FileList object and pass it to the function
          renderProfilePicture(e.target.files[0])
      });
  }

  var renderProfilePicture = function (file)
  {
      // generate a new FileReader object
      var reader = new FileReader();
      // inject an image with the src url
      reader.onload = function(event) {
          var the_url = event.target.result
          $('#cms_profile_pic').attr("src", the_url);
          $('#app_user_profile_picture_data').val(the_url);
      }
      // when the file is read it triggers the onload event above.
      reader.readAsDataURL(file);
  }

  var InitUser = function ()
  {
    // UpdateLocalStorageIcon();
  }

  var InitRegistrationValidation = function() {

    $(document).ready(function() {
      console.log("registration verification");
      $("#show_hide_password a").on('click', function(event) {
          event.preventDefault();
          if($('#show_hide_password input').attr("type") == "text"){
              $('#show_hide_password input').attr('type', 'password');
              $('#show_hide_password i').addClass( "fa-eye-slash" );
              $('#show_hide_password i').removeClass( "fa-eye" );
          }else if($('#show_hide_password input').attr("type") == "password"){
              $('#show_hide_password input').attr('type', 'text');
              $('#show_hide_password i').removeClass( "fa-eye-slash" );
              $('#show_hide_password i').addClass( "fa-eye" );
          }
      });
      
  });
  }

  // Explicitly reveal public pointers to the private functions
  // that we want to reveal publicly
  return {
      supports_html5_storage   : supports_html5_storage,
      UpdateLocalStorageIcon : UpdateLocalStorageIcon,
      showLocalStorage : showLocalStorage,
      insertToolButton : insertToolButton,

      InitProfilePic : InitProfilePic,
      renderProfilePicture : renderProfilePicture,

      InitUser : InitUser,
      InitRegistrationValidation : InitRegistrationValidation,
  }
});


export default VcmsUsers;


// $( document ).ready( function(){
//     // VcmsUsers.UpdateLocalStorageIcon();
//     if (typeof(ArticlesClientApi) != "undefined")
//     {
//         ArticlesClientApi.OnLoadSuccess = function(a){
//             VcmsUsers.OnArticleLoaded(a);
//         }
//         ArticlesClientApi.OnLoadFail = function(e){
//             VcmsUsers.OnArticleLoadFailed(e);
//         }
//         // VcmsUsers.AjaxifyLinks();
//     }

//     // if (window.show_fp_journals == 1)
//     //     animateJournals();

//   });

//   function HandleSearchBox()
//   {
//         var kw = $('#search_keyword').val();
//         var durl = Routing.generate('search', {'searchkey': kw} );
//         window.location = durl;
//         return false;
//   }

