var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "vue_journalslist" } },
    [
      _vm.show_title
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "frontpage_section_header",
                attrs: { align: "center" },
              },
              [_vm._v(" ARCHIVES ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card-group",
        { attrs: { deck: "" } },
        _vm._l(_vm.journals, function (journal) {
          return _c(
            "b-card",
            {
              key: journal.editionid,
              staticClass:
                "journal_card shadow-sm p-2 mb-5 bg-white rounded p-0",
            },
            [
              _c("a", {
                staticClass: "stretched-link",
                attrs: { href: journal.url },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col" }, [
                  _c("img", {
                    staticClass: "p-0 mb-2 journal_vuecover",
                    attrs: {
                      src: journal.titleimage_path,
                      "img-left": "",
                      "img-alt": "Image",
                    },
                  }),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "b-card-title",
                      {
                        staticClass: "sm-3 mt-1",
                        staticStyle: { "font-size": "1em" },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "stretched-link",
                            attrs: { href: journal.url },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(journal.editiontitle) +
                                ", " +
                                _vm._s(journal.editionyear) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "blog_date" }, [
                _vm._v(
                  "  Vol. " +
                    _vm._s(journal.editionvol1) +
                    " No. " +
                    _vm._s(journal.editionno1) +
                    " "
                ),
              ]),
              _vm._v(" "),
              journal.editionvol2 != 0
                ? _c("span", { staticClass: "blog_date" }, [
                    _vm._v(
                      " and Vol. " +
                        _vm._s(journal.editionvol2) +
                        " No. " +
                        _vm._s(journal.editionno2) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _vm.moreToShow
        ? _c(
            "b-container",
            {
              staticClass: "shadow p-3 mb-5 bg-light rounded morebtn_style",
              attrs: { fluid: "" },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "no-small-screen",
                      staticStyle: { "text-align": "center" },
                    },
                    [
                      _c("label", [
                        _vm._v(" " + _vm._s(_vm.remaining) + " more ! "),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "font-size": ".8em" },
                          attrs: { variant: "info" },
                          on: { click: _vm.ShowMore },
                        },
                        [
                          _c("span", { staticClass: "fa fa-plus-circle" }),
                          _vm._v(" Show More \r\n             "),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticStyle: { "font-size": ".8em" },
                          attrs: { variant: "info" },
                          on: { click: _vm.ShowAll },
                        },
                        [
                          _c("span", {
                            staticClass: "fa fa-arrow-circle-down",
                          }),
                          _vm._v(" Show All\r\n             "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }