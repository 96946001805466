<script>

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

import JournalSlide from './journal_slide.vue'

var JournalVueSlides = Vue.component("journal-vue-slides",
{
    props : {
        JournalsData : Array,
    },

    data : function()
    {
        return {
            active_slide : 0,
        }
    },

    computed: {        
    },

    methods:{        
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      },
    
    setupCarousel : function()
    {          
        $("#myCarousel1").carousel();
    },
    isActive : function(id){
        return (id == this.active_slide);
    }
    },

    mounted: function () {
        this.setupCarousel();
    }

});


export default JournalVueSlides;

</script>

<template>
<div class="container-fluid p-0 m-0">
  
    <!-- <b-carousel
      id="carousel-2"
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
        <b-carousel-slide v-for="jd in JournalsData" v-bind:class="{ item: true, active : isActive(jd.slide_number)}" v-bind:key="jd.slide_number">
                <journal-vue-slide v-bind:JournalData="jd"> </journal-vue-slide> 
        </b-carousel-slide>
    </b-carousel>    -->


<div id="myCarousel" class="carousel slide p-0 m-0" data-ride="carousel">

  <!-- <ol class="carousel-indicators" >
      <li v-for="jd in JournalsData" v-bind:key="jd.slide_number"       
            data-target="#myCarousel" v-bind:data-slide-to="jd.slide_number" 
            v-bind:class="{active : isActive(jd.slide_number)}" >
      </li>
  </ol> -->

    <div class="carousel-inner p-0 m-0 mb-1" role="listbox" style="margin-bottom:1em;">
        <div class="carousel-item" v-bind:key="jd.slide_number" v-for="jd in JournalsData" v-bind:class="{ item: true, active : isActive(jd.slide_number)}" >
            <journal-vue-slide v-bind:JournalData="jd"> </journal-vue-slide>
        </div>        
    </div>
    
    <a class="carousel-control-prev" href="#myCarousel" data-slide="prev">
        <span class="carousel-control-prev-icon" style="font-size:1.2em;"></span>
        <span class="sr-only">Previous</span>
    </a>

    <a class="carousel-control-next" href="#myCarousel" data-slide="next">
        <span class="carousel-control-next-icon" style="font-size:1.2em"></span>
        <span class="sr-only">Next</span>
    </a>

</div>


</div>
</template>


<style>
.carousel .carousel-indicators li {
  background-color: #fff;
  background-color: rgba(70, 70, 70, 0.25);
}

.carousel .carousel-indicators .active {
  background-color: #444;
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%666666' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%666666' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

</style>