<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="40000"
      background="#ffabab"      
       img-height="300px"
      fade      
      style="padding:0px;vertical-align:top;padding-top:1em;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >


    <!-- Slide with blank fluid image to maintain slide aspect ratio -->
      <b-carousel-slide caption="hannan"
        class="vcms_quote_slide" img-blank >

        <p> <img slot="img" class="qvcms_image" src="images/authimages/HanaAshrawi.jpg" > 
        ’’The Journal provides a valuable opportunity for sustaining a process of communication essential to genuine peacemaking.” 
          -- Dr. Hanan Ashrawi, Palestinian spokeswoman " </p>
            
      </b-carousel-slide>


<!-- 
      <b-carousel-slide caption="" class="vcms_quote_slide" >
          <div>
            <div style="float:left"><img class="qvcms_image" align="left" src="images/vcmsq_images/BernardLown.jpg"></div>
          
          <p class="vcms_quote" style="font-size:.9em">
            The journal accords with the esseritial thrust of peacemaking in the Middle East. 
            Peace will not be decided by politicians, but will evolve through grassroots engagement 
            in such confidence-building measures."
                -- Bernard Lown,M.D., Nobel Peace Prize 1985
          </p>
        </div>
      </b-carousel-slide>
      
      <b-carousel-slide caption="" class="vcms_quote_slide" >
        <div>
          <div style="float:left"><img class="qvcms_image" align="left"  src="images/vcmsq_images/FaisalHusseini.jpg"></div>
        <p class="vcms_quote"  style="font-size:.9em">
          "The journal provides a Unique platform for Palestinian-Israeli dialogue [and] furnishes a wealth of
          information and learned comments on the issues at the heart of the Israeli-Palestinian dispute. “
              -- The late Faisal Husseini of Jerusalem, Palestinian leader
        </p>
        </div>
      </b-carousel-slide>

      <b-carousel-slide caption=""  class="vcms_quote_slide" >
        <div>
          <div style="float:left"><img class="qvcms_image" align="left"  src="images/vcmsq_images/Samuel_W._Lewis.jpg"></div>
        <p class="vcms_quote">          
          "The Palestine-Israel Journal undoubtedly fills an important gap in literature. 
            You can count me among your admirer."
              -- Samuel W. Lewis, former U.S. ambassador in Israel
        </p>
        </div>
      </b-carousel-slide>
      
      <b-carousel-slide caption=""  class="vcms_quote_slide" >
        <div>
          <div style="float:left"><img class="qvcms_image" align="left"  src="images/vcmsq_images/dkurtzer.jpg"></div>
          <p class="vcms_quote" style="font-size:.9em">
            “I have found the Journal to be an invaluable resource, always focusing on
            issues of importance in the peace process and always providing the necessary
            balance between the views of Palestinians and Israelis. It does not preach: it
            informs, elucidates and challenges the reader to think about the issues.”
                -- Professor Daniel Kurtzer, Princeton University,
                  former U.S. Ambassador to Israel and Egypt
          </p>
        </div>
      </b-carousel-slide>


      <b-carousel-slide caption=""  class="vcms_quote_slide" >
        <div>
          <div style="float:left"><img class="qvcms_image" src="images/authimages/GolanGalia.jpg" ></div>
          <p class="vcms_quote">          
            “The Palestine-Israel Journal has proven itself as an extremely useful, and
            reliable informative source, that enrich our understanding of the complex
            Israeli-Palestinian conflict.”
                -- Professor Galia Golan (Emerita, Hebrew University of Jerusalem)
          </p>
          </div>
      </b-carousel-slide>

        <b-carousel-slide caption="" class="vcms_quote_slide"  >
          <div> 
            <div style="float:left"><img class="qvcms_image" src="images/authimages/Edward_Kaufman.jpg" ></div>
            <p class="vcms_quote">          
            “The Palestine-Israel Journal, its publications and public activities, stands out
            like a beacon of light in the midst of the darkness.”

            -- Professor Edward (Edy) Kaufman, Senior Research Associate, Center for
          International Development Conflict Management
        </p>
          </div>
      </b-carousel-slide> -->

    </b-carousel>
    
  </div>
</template>

<script>

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue);

var JournalQuotes = Vue.component("journal-vue-quotes", {
    data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart(slide) {
        this.sliding = true
      },
      onSlideEnd(slide) {
        this.sliding = false
      }
    }
  });

  export default JournalQuotes;
</script>

<style>
.vcms_quote
{
  color: black;
  background: white;
  padding: 1em;      
  vertical-align: middle;
}

.vcms_quote_slide 
{
  width: 100%;
  height: auto;
  padding: 0em;
  margin-top:1em;
  margin-bottom: 1em;
  font-weight: normal;
  font-size: 1.1em;
}
div.carousel-inner {
  margin-left:0px;
  padding-left: 0px;
  text-align: center;
  vertical-align: top;
}
/* div.carousel-caption {
  width: 100%;
  height: auto;
  margin-left:1em;
  margin-top:1em;
  padding-left:1px;
  padding-top:1px;
} */
.qvcms_image {
  border-radius: 8px;width:90px;height:100px;float:left;
}

</style>