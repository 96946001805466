<script>

import Vue from 'vue';

const authors_list = require('../../content/author/all.json');

var AuthorsCatalog = Vue.component("vcms-authors-catalog",
{
    data : function()
    {
        var authorsdata = new Array();

        var numPerPage = 10;

        var all_authors = this.processAuthors();
        var pageauthors = this.paginate(all_authors, numPerPage, 1);
        authorsdata = pageauthors;

        var nextpage = 2;

        var maxpage = Math.ceil( all_authors.length / numPerPage );

        return {
            all_authors : all_authors,
            authors : authorsdata,            
            show_title : true,
            nextPage : nextpage,
            maxPage : maxpage,
            numPerPage : numPerPage,
            search_name : "",
            state : true,
            key_timeout : null,

            nav_letters: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z' ]
        };
    },
    computed: {
        moreToShow : function()
        {
            return (this.nextPage <= this.maxPage);
        },
        remaining : function()
        {
            return this.all_authors.length - ((this.nextPage - 1)*this.numPerPage);
            return (this.nextPag <= this.maxPage);
        },
    },
    watch: {
        search_name : function()
        {            
            clearTimeout(this.key_timeout);
            var f = this.SearchAuthors;
            this.timeout = setTimeout(function(){ 
                console.log("Searching...");
                f(); },1000);
        }
    },
    methods: {
        onSubmit : function (evt) {
            evt.preventDefault()
        },
        processAuthors : function()
        {
            var authlist = new Array();
            authors_list.forEach(element=>{
                if (element.authid != 5) {
                    var e2 = element;            
                    e2.author_url = element.seo_urlpath; // Routing.generate('author', {'authid' : element.authid});
                    e2.authdesc_lowercase = element.authdesc.toLowerCase();
                    authlist.push(e2);
                }                
            });
            return authlist;
        },
        paginate : function (array, page_size, page_number) 
        {
            --page_number; 
            var sublist =  array.slice(page_number * page_size, (page_number + 1) * page_size);
            var pagedata = new Array();
            sublist.forEach(element => {
                if (element.authid != 5)
                try
                {
                    var e2 = element;            
                    e2.author_url = Routing.generate('author', {'authid' : element.authid});
                    pagedata.push(e2);
                }
                catch(err)
                {
                    console.error(err);
                }
            });

            return pagedata;
        },
        filterByLetter : function (sublist, ltr) 
        {            
            var pagedata = new Array();
            sublist.forEach(element => {
                if (element.authid != 5)
                try
                {
                    var e2 = element;
                    e2.author_url = Routing.generate('author', {'authid' : element.authid});
                    
                    var lastname = '';

                    var nameparts = e2.authname.split(' ');
                    if (nameparts.length==3){
                        var firstname = nameparts[0];
                        var middlename = nameparts[1];
                        var lastname = nameparts[2];
                    }
                    else
                    if (nameparts.length==2){
                        var firstname = nameparts[0];
                        var middlename = '';
                        var lastname = nameparts[1];
                    }

                    if ( (lastname.toUpperCase().startsWith(ltr))  )
                        pagedata.push(e2);
                }
                catch(err)
                {
                    console.error(err);
                }
            });

            return pagedata;
        },
        filterByKeyword : function (sublist, kword) 
        {            
            kword = kword.toLowerCase();
            var pagedata = new Array();
            sublist.forEach(element => {
                if (element.authid != 5)
                try
                {
                    var e2 = element;
                    e2.author_url = Routing.generate('author', {'authid' : element.authid});
                    
                    var lastname = '';
                    var firstname ='';

                    var nameparts = e2.authname.split(' ');
                    if (nameparts.length==3){
                        var firstname = nameparts[0];
                        var middlename = nameparts[1];
                        var lastname = nameparts[2];
                    }
                    else
                    if (nameparts.length==2){
                        var firstname = nameparts[0];
                        var middlename = '';
                        var lastname = nameparts[1];
                    }

                    if ( (lastname.toLowerCase().search(kword) != -1) 
                        || (firstname.toLowerCase().startsWith(kword) )
                        || (element.authdesc_lowercase.search(kword) != -1) )
                        pagedata.push(e2);
                }
                catch(err)
                {
                    console.error(err);
                }
            });
            console.log("Finished Searching, Found : "+ pagedata.length + " records ");
            return pagedata;
        },
        ShowMore : function()
        {
            var pageauthors = this.paginate(this.all_authors, 10, this.nextPage++);
            this.authors = this.authors.concat(pageauthors);
        },
        NavByLetter : function(ltr)
        {
            console.log("Nav to Letter: " + ltr);
            var pageauthors = this.filterByLetter(this.all_authors, ltr);
            this.authors = pageauthors;
        },
        SearchAuthors : function()
        {
            // console.log("Nav to Letter: " + search_name);
            var pageauthors = this.filterByKeyword(this.all_authors, this.search_name);
            this.authors = pageauthors;
        }
    }

});

export default AuthorsCatalog;

</script>

<template>

<div id="vue_authors_list">

<div  v-if="show_title" class="p-0 m-0 frontpage_section_header" align="center"> AUTHORS </div>

    <b-container fluid class="border-dark morebtn_style mb-2 ml-0 mr-0 pt-2 pb-1" >
         <b-row>            
            <b-col sm="6">
                        <b-form inline @submit.stop.prevent>
                          <b-form-group inline
                            id="fieldset-1"
                            description="Search authors by name or keywords. ex: 'Birzeit', 'Hebrew Univeristy', 'United Nations', etc "
                            label="Search Authors"
                            label-for="input-authname"                
                            :state="state">
                            <b-form-input id="input-authname" v-model="search_name" trim class="ml-3" width="20"></b-form-input>

                            <b-button @click="SearchAuthors" variant="secondary" class="morebtn_style mt-1 ml-2 btn-sm"> <span class="fa fa-search"> </span> Search </b-button>
                            </b-form-group>
                        </b-form>
            </b-col>
            
            <b-col sm="6">
               <span class="search_result_label"> Showing {{ authors.length }} / {{ all_authors.length }} Authors </span>
            </b-col>                    

         </b-row>            

    </b-container>

    <b-container fluid class="border-dark morebtn_style mb-2 ml-0 mr-0 pt-2 pb-1" >
        <b-row>
             <b-col sm="1" class="no-small-screen">
                 <label variant="info"> Filter </label>
                 <b-button-group vertical>                    
                 <b-button v-for="navltr in nav_letters" :key="navltr" @click="NavByLetter(navltr)"
                    style="font-size:1.6em" > {{ navltr }} </b-button>
                 </b-button-group>

             </b-col>

            <b-col sm="11">

                <b-card-group deck class="mb-2">

                    <b-card v-for="author in authors" v-bind:key="author.authid" 
                        class="author_card shadow-sm p-1 mr-1 mb-2 bg-white rounded" >
                        
                        <div slot="footer" style="font-size:1em">  {{ author.authname }} </div>

                        <a class="stretched-link" :href="author.author_url"></a>
                        <img :src="author.titleimage_path" class="ml-1 pl-0 blog_auth_pic_mainpage " align="left" />
                        <span class="authdesc_div">                             
                             {{ author.authdesc }} </span>                           
                        
                    </b-card>
                
                </b-card-group>

            </b-col>
        </b-row>

    </b-container>

    <!-- <b-container class="shadow p-3 bg-light rounded morebtn_style" > 
      <b-row>
        <b-col style="text-align:center">
            <label> {{ remaining }} more ... </label> 
        </b-col>

        <b-col style="text-align:center">
        <b-button v-if="moreToShow" @click="ShowMore" variant="info">
             <span class="fa fa-plus-circle">Show more </span> 
             </b-button>
        </b-col>    
      </b-row>
    </b-container> -->
    
</div>


</template>


<style>

.authdesc_div {
    width:auto;
    height:3em;    
    overflow: hidden;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: .8em;
    word-wrap: normal;
    margin-top:0px;
    padding-top:0px;
}

.author_card
{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1em;
    min-width: 40%;    
}

.morebtn_style {
    font-family: Arial, Helvetica, sans-serif;
}

.search_result_label {
    font-size:.7em;
    font-weight: 500;
    color: #555555;
}
</style>
