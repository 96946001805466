<script>

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

// const journalSlideData  = require('../../data/journal_slides.gen.json');

var JournalSlide = Vue.component("journal-vue-slide",
{
    props : {
        JournalData : Object,
    },
    computed : { 

        jlink : function(){
            return Routing.generate('journal', { jid: this.JournalData.editionid });
        },
        coverImg : function()
        {
            return "#";
        },
        titleStr : function()
        {
            return this.JournalData.editiontitle;
        },
        VolStr : function()
        {
            return "Vol. " + this.JournalData.editionvol + " No. " + this.JournalData.editionno;
        }        
    },
    methods: {
        getArticleLink(aid)
        {
            return Routing.generate('articles', { articleid : aid } );
        },
        getSeoLink(arec)
        {
            return arec.seo_urlpath;
        }
    },

    data : function()
    {
        return {            
        }
    },
});

export default JournalSlide;

</script>


<template> 
<div>

    <div class="container">

    <div class="row" id="slider_row">

        <div class="col-md-4" style="text-align:center;">

            <a v-bind:href="jlink" class="fpjournal_link">  
                <img v-bind:src="JournalData.titleimage_path" class="fp_journal_img" >
            </a>
            <div style="margin-top:1em;">
                 <a v-bind:href="jlink" class="fp_j_listitem" > Full Table of Contents  </a> 
            </div>
            <div class="no-wide-screen fp_box_title" style="text-align:left">
                <span style="padding-top:1em; padding-right:.5em; display:block"> 
                    <a  v-bind:href="jlink" class="fpjournal_link" style="font-size:1.2em;"> {{ JournalData.editiontitle }} </a>
                    </span>
                    <span style="font-weight:normal;font-weight:bold"> {{VolStr}}</span>                
            </div>
        </div>	

        <div class="col-md-6 no-small-screen fp_box_title" >
            <div style="padding-left:11em;"> 

                <div>
                    <span style="padding-top:1em; padding-right:.5em; display:block; text-align:left;height:6em;"> 
                        <a v-bind:href="jlink" class="fpjournal_link" style="font-size:1.5em;"> {{ JournalData.editiontitle }} </a>
                    </span>

                    <span style="font-weight:normal;font-weight:bold"> {{VolStr}} </span>
                </div>

                
                <div style="margin-top:2em;">
                    <ul style="padding-left:.5em;margin-top:1em;">
                        <li v-for="jarticle in JournalData.slide_articles" v-bind:key="jarticle.articleid" class="fp_j_listitem" >
                            <span class="fa fa-angle-right"/> <a v-bind:href="getSeoLink(jarticle)">  {{ jarticle.atitle }} </a>
                            
                                <ul style="padding:0"> 
                                    <li  v-for="jaa in jarticle.authors" v-bind:key="jaa.authid" style="list-style: none;display:inline">
                                        <span style="font-size: .9em"> {{jaa.prefix}} </span>
                                        <span class="slider_authname">  
                                        {{ jaa.authname }} 
                                        </span>
                                    </li>
                                </ul>                         

                        </li>                        
                    </ul>                    
                </div>
           </div>

        </div>

    </div>
  </div>

</div>
</template>

<style>

.fp_j_listitem
{
	font-size: 1.1em;
	font-weight: 500;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: normal;
	padding-left:0px;
    padding-bottom:1em;
    list-style: none;
}	

@media only screen and (min-width: 960px) {

    .fp_j_listitem
    {
        font-size: 1.2em;
    }
    .fp_box_title
    {
        min-width: 600px;
    }

}

.fp_journal_img
{
    width: 360px;
    height: 470px;
    padding: .2em;
    margin-bottom:.5em;
    margin-top:.5em;    
}


#slider_row { 
    text-align:left;
}

@media only screen and (max-width: 630px) {
    #slider_row { 
        text-align:center;
    }

    .fp_journal_img
    {
        width: 300px;
        height: 390px;
        padding: .2em;
        margin-bottom:.5em;
        margin-top:.5em;    
    }

}

@media only screen and (max-width: 630px) {
    .fp_j_title {
        display: none;
    }
}

.show_border
{
    border-color:red;
    border-style: solid;
    border-width:1px;
}

.fp_box_title
{
    min-width: 450px;
	font-size: .8em;	
	font-weight: normal;	    
	margin-top:0px;
	word-wrap: normal;
	font-family: Arial, Helvetica, sans-serif;
}

.front_page_box
{
	border-width:1px;
	border-style:solid;
	padding: 10px;	
	height: 16em;
	
}

.frontpage_journal
{		
	/*height:350px;	*/	
	margin-left:0px;
	border-color: #ffffff;

}

.slider_authname
{
	font-size: .8em;
	color: brown;
    font-weight: bold;    
    margin-left: .0em;
    padding-left:0;
}

#toctext
{
	padding-top:1em;
	padding-left:1em;
	font-size:.7em;
	font-weight: bold;
}
</style>
