var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "vue_authors_list" } },
    [
      _vm.show_title
        ? _c(
            "div",
            {
              staticClass: "p-0 m-0 frontpage_section_header",
              attrs: { align: "center" },
            },
            [_vm._v(" AUTHORS ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-container",
        {
          staticClass: "border-dark morebtn_style mb-2 ml-0 mr-0 pt-2 pb-1",
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "6" } },
                [
                  _c(
                    "b-form",
                    {
                      attrs: { inline: "" },
                      on: {
                        submit: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            inline: "",
                            id: "fieldset-1",
                            description:
                              "Search authors by name or keywords. ex: 'Birzeit', 'Hebrew Univeristy', 'United Nations', etc ",
                            label: "Search Authors",
                            "label-for": "input-authname",
                            state: _vm.state,
                          },
                        },
                        [
                          _c("b-form-input", {
                            staticClass: "ml-3",
                            attrs: {
                              id: "input-authname",
                              trim: "",
                              width: "20",
                            },
                            model: {
                              value: _vm.search_name,
                              callback: function ($$v) {
                                _vm.search_name = $$v
                              },
                              expression: "search_name",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "morebtn_style mt-1 ml-2 btn-sm",
                              attrs: { variant: "secondary" },
                              on: { click: _vm.SearchAuthors },
                            },
                            [
                              _c("span", { staticClass: "fa fa-search" }),
                              _vm._v(" Search "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("b-col", { attrs: { sm: "6" } }, [
                _c("span", { staticClass: "search_result_label" }, [
                  _vm._v(
                    " Showing " +
                      _vm._s(_vm.authors.length) +
                      " / " +
                      _vm._s(_vm.all_authors.length) +
                      " Authors "
                  ),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-container",
        {
          staticClass: "border-dark morebtn_style mb-2 ml-0 mr-0 pt-2 pb-1",
          attrs: { fluid: "" },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { staticClass: "no-small-screen", attrs: { sm: "1" } },
                [
                  _c("label", { attrs: { variant: "info" } }, [
                    _vm._v(" Filter "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-button-group",
                    { attrs: { vertical: "" } },
                    _vm._l(_vm.nav_letters, function (navltr) {
                      return _c(
                        "b-button",
                        {
                          key: navltr,
                          staticStyle: { "font-size": "1.6em" },
                          on: {
                            click: function ($event) {
                              return _vm.NavByLetter(navltr)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(navltr) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { sm: "11" } },
                [
                  _c(
                    "b-card-group",
                    { staticClass: "mb-2", attrs: { deck: "" } },
                    _vm._l(_vm.authors, function (author) {
                      return _c(
                        "b-card",
                        {
                          key: author.authid,
                          staticClass:
                            "author_card shadow-sm p-1 mr-1 mb-2 bg-white rounded",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: { "font-size": "1em" },
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [_vm._v("  " + _vm._s(author.authname) + " ")]
                          ),
                          _vm._v(" "),
                          _c("a", {
                            staticClass: "stretched-link",
                            attrs: { href: author.author_url },
                          }),
                          _vm._v(" "),
                          _c("img", {
                            staticClass: "ml-1 pl-0 blog_auth_pic_mainpage",
                            attrs: {
                              src: author.titleimage_path,
                              align: "left",
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "authdesc_div" }, [
                            _vm._v(
                              "                             \r\n                             " +
                                _vm._s(author.authdesc) +
                                " "
                            ),
                          ]),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }