var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "vue_blogslist" } },
    [
      _vm.show_title
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "frontpage_section_header mt-1 mb-3",
                attrs: { align: "center" },
              },
              [_vm._v(" BLOGS ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-card-group",
        { attrs: { deck: "" } },
        _vm._l(_vm.blogs, function (blog) {
          return _c(
            "b-card",
            {
              key: blog.bid,
              staticClass: "blog_card shadow-sm pt-1 mb-2 bg-white rounded",
              attrs: { "img-left": "" },
            },
            [
              _c("b-card-img", {
                staticClass: "ml-1 pl-0 blog_auth_pic",
                attrs: {
                  "img-left": "",
                  align: "left",
                  src: blog.authors[0].titleimage_path,
                  width: "90",
                  height: "110",
                },
              }),
              _vm._v(" "),
              _c("b-card-text", [
                _c(
                  "a",
                  { staticClass: "stretched-link", attrs: { href: blog.url } },
                  [_vm._v(" " + _vm._s(blog.title) + " ")]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "mb-1" }, [
                  _c("span", [_vm._v(" By ")]),
                  _vm._v(" "),
                  _c("a", { attrs: { href: blog.author_url } }, [
                    _vm._v(
                      " " +
                        _vm._s(blog.authors[0].authname) +
                        " " +
                        _vm._s(blog.authors[0].authlname) +
                        " "
                    ),
                  ]),
                  _vm._v(" "),
                  blog.authors.length == 2
                    ? _c("span", [
                        _vm._v(" \r\n                               and "),
                        _c("a", { attrs: { href: blog.author_url } }, [
                          _vm._v(
                            " " +
                              _vm._s(blog.authors[1].authname) +
                              " " +
                              _vm._s(blog.authors[1].authlname) +
                              " "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "blog_date" }, [
                  _vm._v(" On " + _vm._s(blog.cdate) + " "),
                ]),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "b-button",
        { attrs: { variant: "info" }, on: { click: _vm.ShowMore } },
        [
          _c("span", { staticClass: "fa fa-plus-circle" }),
          _vm._v(" Blogs Archive ...\r\n    "),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }