<script>

import Vue from 'vue';


const journalArvhices = require('../../content/journal/all.json');

var JournalsArchive = Vue.component("vcms-journals-archive",
{
    data : function()
    {
        var journals_list = this.processJournals();

        var journals_data = new Array();
        
        var numPerPage = 9;

        var jpage = this.paginate(journals_list, numPerPage, 1);
        journals_data = jpage;

        var nextpage = 2;

        var maxpage = Math.ceil( journals_list.length / numPerPage );

        return {
            journals_list : journals_list,
            journals : journals_data,            
            show_title : true,
            nextPage : nextpage,
            maxPage : maxpage,
            numPerPage : numPerPage,
        };
    },
    computed: {
        moreToShow : function()
        {
            //return (this.journals.length < this.journals_list.length);
            return (this.remaining > 0);
        },
        remaining : function()
        {
            return this.journals_list.length - ((this.nextPage - 1)*this.numPerPage);
            return (this.nextPag <= this.maxPage);
        }

    },
    methods: {
        processJournals: function()
        {
            var revlist = journalArvhices.reverse();
            var newlist = new Array();
            revlist.forEach(element => {
                try
                {
                    var e2 = element;            
                    e2.url = Routing.generate('journal', {'jid' : element.editionid } );
                    newlist.push(e2);
                }
                catch(err)
                {
                    console.error(err);
                }                
            });
            return newlist;
        },
        paginate : function (array, page_size, page_number) 
        {
            --page_number;
            var pagedata =  array.slice(page_number * page_size, (page_number + 1) * page_size);
            return pagedata;
        },        
        ShowMore : function()
        {            
            var jpage = this.paginate(this.journals_list, 10, this.nextPage++);
            this.journals = this.journals.concat(jpage);
        },        
        ShowAll : function()
        {            
            var jpage = this.paginate(this.journals_list, this.journals_list.length + 1, 1);
            this.journals = jpage;
        }
    }    

});

export default JournalsArchive;

</script>

<template>

<div id="vue_journalslist">

    <div v-if="show_title">
        <div align="center" class="frontpage_section_header" > ARCHIVES </div>        
    </div>

    <b-card-group deck>

        <b-card class="journal_card shadow-sm p-2 mb-5 bg-white rounded p-0" v-for="journal in journals" v-bind:key="journal.editionid"> 
            <a class="stretched-link" v-bind:href="journal.url"></a>
            <div class="row">
                <div class="col">
                        <img :src="journal.titleimage_path" class="p-0 mb-2 journal_vuecover " img-left img-alt="Image" />
                </div>
                <div class="col">
                <b-card-title class="sm-3 mt-1" style="font-size:1em;">
                                <a class="stretched-link" v-bind:href="journal.url"> {{ journal.editiontitle }}, {{ journal.editionyear }} </a>
                            </b-card-title>
                </div>
            </div>

            
            <span class="blog_date">  Vol. {{ journal.editionvol1 }} No. {{ journal.editionno1 }} </span>
            <span v-if="(journal.editionvol2 != 0)" class="blog_date"> and Vol. {{ journal.editionvol2 }} No. {{ journal.editionno2 }} </span>            
                 
        </b-card>
    
    </b-card-group>

    <b-container fluid v-if="moreToShow" class="shadow p-3 mb-5 bg-light rounded morebtn_style" > 
      <b-row>
        <b-col style="text-align:center" class="no-small-screen">
            <label> {{ remaining }} more ! </label> 
        </b-col>

        <b-col style="text-align:center">
        <b-button @click="ShowMore" variant="info" style="font-size:.8em;" >
             <span class="fa fa-plus-circle"> </span> Show More 
             </b-button>
        </b-col>
        <b-col style="text-align:center">
        <b-button @click="ShowAll" variant="info" style="font-size:.8em;" >
             <span class="fa fa-arrow-circle-down"> </span> Show All
             </b-button>
        </b-col>        
      </b-row>
    </b-container>
    
</div>


</template>


<style>

@media screen and (max-width: 600px) {

    .journal_vuecover  {
        border-width:1px;
        width:100px;
        height:110px;

        margin-bottom:1em;
    }

    .journal_card {
        font-family: Arial, Helvetica, sans-serif;
        min-width: 30%;
        height: auto;
    }
}

@media screen and (min-width: 600px) {

    .journal_card
    {
        font-family: Arial, Helvetica, sans-serif;
        min-width: 30%;        
        height: 20em;
    }

    .journal_vuecover {
        border-width:1px;
        width:170px;
        height:190px;
        margin-bottom:1em;
    }
}




.morebtn_style {
    font-family: Arial, Helvetica, sans-serif;
}


</style>
