var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "container-fluid p-0 m-0" }, [
    _c(
      "div",
      {
        staticClass: "carousel slide p-0 m-0",
        attrs: { id: "myCarousel", "data-ride": "carousel" },
      },
      [
        _c(
          "div",
          {
            staticClass: "carousel-inner p-0 m-0 mb-1",
            staticStyle: { "margin-bottom": "1em" },
            attrs: { role: "listbox" },
          },
          _vm._l(_vm.JournalsData, function (jd) {
            return _c(
              "div",
              {
                key: jd.slide_number,
                staticClass: "carousel-item",
                class: { item: true, active: _vm.isActive(jd.slide_number) },
              },
              [_c("journal-vue-slide", { attrs: { JournalData: jd } })],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm._m(1),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-prev",
        attrs: { href: "#myCarousel", "data-slide": "prev" },
      },
      [
        _c("span", {
          staticClass: "carousel-control-prev-icon",
          staticStyle: { "font-size": "1.2em" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Previous")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "a",
      {
        staticClass: "carousel-control-next",
        attrs: { href: "#myCarousel", "data-slide": "next" },
      },
      [
        _c("span", {
          staticClass: "carousel-control-next-icon",
          staticStyle: { "font-size": "1.2em" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [_vm._v("Next")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }