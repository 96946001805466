
import CmsCart from './vcms_cart.js';


var CmsStore = (function(cmsClient)
{
    var cmsClient = cmsClient;
    var defaultCart = new CmsCart("default", null);

     // stripe api
    var stripeResponseHandler = function (status, response) 
    {
        var $form = $('#payment-form');
        if (response.error) {
            // Show the errors on the form
            $form.find('.payment-errors').text(response.error.message);
            $form.find('button').prop('disabled', false);

        } else {
            // response contains id and card, which contains additional card details
            var token = response.id;
            // Insert the token into the form so it gets submitted to the server
            $form.append($('<input type="hidden" name="stripeToken" />').val(token));
            // and submit
            $form.get(0).submit();
        }
    };

    var InitOrderForm = function()
    {
		  $('#payment-form').submit(function(event) {
		    var $form = $(this);
		    // Disable the submit button to prevent repeated clicks
		    $form.find('button').prop('disabled', true);
            if ( $form.find('#onlineonly').prop('checked') )
            $form.find('#order_amount').prop('value','25'); 
            else
            if ( $form.find('#student').prop('checked') )
            $form.find('#order_amount').prop('value','50');
            else
            if ( $form.find('#indiv').prop('checked') )
            $form.find('#order_amount').prop('value','60');
            else
            if ( $form.find('#inst').prop('checked') )
            $form.find('#order_amount').prop('value','75');
                   
		    Stripe.card.createToken($form, stripeResponseHandler);
		    // Prevent the form from submitting with the default action
		    return false;
		  });		
    }



    var GetCart = function ()
    {
        return defaultCart;
    }

    return {
        InitOrderForm : InitOrderForm,        
        stripeResponseHandler : stripeResponseHandler,
        GetCart : GetCart,
    }

});


export default CmsStore;