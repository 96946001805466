var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "vue_eventlist" } },
    [
      _vm.show_title
        ? _c("div", [
            _c(
              "div",
              {
                staticClass: "frontpage_section_header mt-1 mb-3",
                attrs: { align: "center" },
              },
              [_vm._v(" News and Events ")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-list-group",
        { staticClass: "vcms_fp_events" },
        _vm._l(_vm.events, function (event) {
          return _c(
            "b-list-group-item",
            {
              key: event.eid,
              staticClass:
                "mb-2 bg-white border-default p-2 pt-1 pb-4 shadow-light",
              attrs: { type: "none" },
            },
            [
              event.showTitleImage
                ? _c("img", {
                    staticStyle: { width: "100%" },
                    attrs: { src: event.titleimage_path, "img-responsive": "" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "stretched-link",
                  class: { event_item_active: event.isActive },
                  staticStyle: { "font-weight": "medium" },
                  attrs: { href: event.url },
                },
                [
                  _vm._v(
                    "\r\n                    " + _vm._s(event.title) + "  "
                  ),
                ]
              ),
              _vm._v(" "),
              event.subtitle != "."
                ? _c("span", [_vm._v(" " + _vm._s(event.subtitle) + " ")])
                : _vm._e(),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }