<script>

import Vue from 'vue';

const journal_blogs = require('../../content/blog/all.json');

journal_blogs.forEach(element=>{
    console.log(" Blog:  " + element)
});

var BlogsArchive = Vue.component("vcms-blogs-archive",
{
    data : function()
    {
        var all_blogs = this.processBlogs();

        var numPerPage = 8;
        var nextpage = 2;
        var maxpage = Math.ceil( all_blogs.length / numPerPage );

        var blogs_data = this.paginate(all_blogs, numPerPage, 1);

        return {
            all_blogs : all_blogs,
            blogs : blogs_data,            
            show_title : true,
            nextPage : nextpage,
            maxPage : maxpage,
            numPerPage : numPerPage,
        };
    },
    computed: {
        moreToShow : function()
        {
            return (this.nextPage <= this.maxPage);
        },
        remaining : function()
        {
            return this.all_blogs.length - ((this.nextPage - 1)*this.numPerPage);            
        }
    },
    methods: {
        processBlogs : function()
        {
            var bloglist = new Array();
            journal_blogs.forEach(element=>{
                if (element.authid != 5) {
                    var e2 = element;
                    var striptitle = element.title.toLowerCase().replace(/(<([^>]+)>)/ig,"");                    
                    var seo_path = striptitle.replace(/\s+/g, '-');
                    var seo_path = seo_path.replace(',','-')
                    var seo_path = seo_path.replace('/','-');
                    e2.url =  Routing.generate('blog', {'blogid' : element.bid, 'kw': seo_path});          
                    e2.author_url = Routing.generate('author', {'authid' : element.authid});
                    bloglist.push(e2);
                }                
            });
            return bloglist;
        },
        paginate : function (array, page_size, page_number) 
        {
            --page_number; 
            var sublist =  array.slice(page_number * page_size, (page_number + 1) * page_size);
            return sublist;
        },        
        ShowMore : function()
        {
            var pageblogs = this.paginate(this.all_blogs, 10, this.nextPage++);
            this.blogs = this.blogs.concat(pageblogs);
        }

    }

});

export default BlogsArchive;

</script>

<template>

<div id="vue_blogslist">

    <div v-if="show_title">
        <div align="center" class="frontpage_section_header"> BLOGS </div>
    </div>

    <b-card-group deck class="mb-2">

        <b-card class="blog_card mb-5 shadow-sm p-3 bg-white rounded" v-for="blog in blogs" v-bind:key="blog.bid" v-bind:BlogData="blog"> 

               <b-card-img left :src="blog.authors[0].titleimage_path" class="ml-1 pl-0 blog_auth_pic_mainpage " 
                
                width="90" height="110" align="left" />


                <b-card-title class="blogcard_title">
                    <a class="stretched-link" v-bind:href="blog.url"> {{ blog.title }} </a></b-card-title>
                <p>
                    <span> By </span> <a class="" v-bind:href="blog.author_url"> {{ blog.authors[0].authname }} {{ blog.authors[0].authlname }} </a>
                        <span v-if="blog.authors.length==2"> 
                                and <a class="" v-bind:href="blog.author_url"> {{ blog.authors[1].authname }} {{ blog.authors[1].authlname }} </a>
                        </span>
                </p>
                <span class="blog_date"> On {{ blog.cdate }} </span>
                 
        </b-card>
    
    </b-card-group>

    <b-container class="shadow p-3 bg-light rounded morebtn_style" > 
      <b-row>
        <b-col style="text-align:center">
            <label> {{ remaining }} more blogs </label> 
        </b-col>

        <b-col style="text-align:center">
        <b-button v-if="moreToShow" @click="ShowMore" variant="info">
             <span class="fa fa-plus-circle"></span> Show More 
             </b-button>
        </b-col>    
      </b-row>
    </b-container>
    
</div>


</template>


<style>

.card-group .card {
    max-width: 25%;
}

.blog_card
{
    font-family: Arial, Helvetica, sans-serif;
    min-width: 45%;
    min-height: 15em;    
}

.morebtn_style {
    font-family: Arial, Helvetica, sans-serif;
}

.blogcard_title { 
    font-size:1.1em;
    line-height :1.3em;
    height:4em;
    overflow: hidden
}

</style>
