
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

import EventsBlock from './events_block/events_block.vue'
import BlogsBlock from './recent_blogs/frontpage_blogs.vue'
import JournalVueSlides from './frontpage/journal_slides.vue'

import VcmsBlogsArchive from './recent_blogs/blogs_archive.vue';

import VcmsJournalArchives from './journal_slides/journals_archive.vue';
import AuthorsCatalog from './authors/authors_catalog.vue'

import JournalQuotes from './frontpage/aboutpij_quotes.vue'

const frontPageData  = require('../content/frontpage.gen.json');

var VcmsVueApp = (function()
{
    var app;    
      
    function getData()
    {       
        return {
            frontpage_data : frontPageData
        }
    }

    var render = function(element, cli) {
        var d = getData();
        d.cmsClient= cli;

        app = new Vue({
            el: '#full_site_page', element, // '#vcms_vueroot',
            data: getData(),           
          })

        return app;
    }

    return {            
        render  : render       
    }
})();

export default VcmsVueApp;
