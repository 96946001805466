<template>
<div>
    <div class="search_app_title">  {{  page_title }} </div>

<b-container fluid>
    <b-row>
        <b-col cols="12">
            <b-form @submit="onSubmit" @reset="onReset" v-if="show" inline class="search_form_class">

                <b-form-group                          
                    id="input-group-1"
                    label="Search Keyword:"
                    label-for="input-keyword"
                    :description="form.keyword"
                    style="width:100%;margin-right:1.5em;">

                    <b-form-input                            
                        id="input-keyword"
                        v-model="form.keyword"
                        type="text"
                        required
                        placeholder="Enter Keywords">
                    </b-form-input>

                    <b-button type="submit" variant="primary" size="sm">                            
                        <span class="fa fa-search"> Search </span>
                        </b-button>

                    <b-form-select 
                            id="select-page-size"
                            style="width:5em;"
                            v-model="perPage"
                            :options="[5, 10, 15, 20]"
                            :text="perPageText" class="m-md-2">                        
                    </b-form-select>

                </b-form-group>
                
            </b-form>
    </b-col>

</b-row>    

    <b-row v-if="showResults">
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table">
        </b-pagination>

    </b-row>

    <b-row v-if="showResults">
        <div style="overflow:auto; height: 30em;">

        <p class="mt-3">Article Results - Page: {{ currentPage }}</p>

        <b-table class="results_table" id="my-table"
            :sort-by="sortByField"
            :sort-desc="true"
            :items="items"                    
            :per-page="perPage"
            :current-page="currentPage"
            selectable
            small
            :primary-key="sortByField"

            @row-click="onRowClick"
            >            
        </b-table>
    </div>

    </b-row>

    <b-row>
        <b-col cols="12">                
        </b-col>
            
    </b-row>

</b-container>


    <div v-if="showResults" class="search_app_footer">  {{  countResults }} </div>


</div>

</template>

<script>

import Vue from 'vue'

import VCMSSearchPage from './search_page.js';

// import jtoc from '../../content/journal/10.json';

var VueSearch = Vue.component("vcms-content-search",{

    data: function()
    {
        return {
           page_title: 'Archives Database Search',
           show : true,
           form : { 
                keyword : null
           },
           submitted : false,
           perPage : 5,
           currentPage: 1,
           fields: [{
                        key: 'id',
                        sortable: false
                    },
                    {
                        key: 'title',
                        sortable: true
                    },{
                        key: 'subtitle',
                        sortable: true
                    }
           ],
           items: [],
            //     { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
            //     { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
            //     { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
            //     { age: 38, first_name: 'Jami', last_name: 'Carney' }
            // ],
            search_result : new Array(), // jtoc.book.toc,
            searchApi : new VCMSSearchPage()
        }
    },
    computed: {

        rows() {
            return this.items.length
        },

        perPageText()
        {
            return 'Results Per Page ' + this.perPage;
        },

        countResults() 
        {
            return 'Query returned ' + this.items.length + " Results.";
        },
        sortByField(){
            return 'id';
        },
        showResults()
        {
            return ( this.form.keyword!=null && this.submitted );
        }
    },

    mounted: function () {

            this.searchApi.SetHandlers(this.OnSearchRequestSuccess, this.OnSearchRequestFail);
            // this.loadTestData();
    },

    methods: {

        addArticles : function(alist, slist)
        {
            slist.forEach(function(a){

                var rec = {
                    id : a.articleid,
                    title : a.atitle,
                    subtitle : a.subtitle,
                };

                // console.log("added: " + a.atitle);
                alist.push(rec);
            });
        },

        onRowClick : function(event)
        {
            var aurl = Routing.generate("articles", { id : event.row.id} );
            window.open(aurl);
            //window.location.href = `/edit/hello-${event.row.id}`
        },

        loadTestData : function()
        {
            this.items.length = 0;
            var alist = this.items;
            // this.addArticles( alist, jtoc.book.toc[6].Articles);
            // this.addArticles( alist, jtoc.book.toc[7].Articles);
            // this.addArticles( alist, jtoc.book.toc[9].Articles);
            // this.addArticles( alist, jtoc.book.toc[10].Articles);
            // this.addArticles( alist, jtoc.book.toc[15].Articles);
            // this.addArticles( alist, jtoc.book.toc[22].Articles);
            // this.addArticles( alist, jtoc.book.toc[24].Articles);
            // this.addArticles( alist, jtoc.book.toc[25].Articles);
            // this.addArticles( alist, jtoc.book.toc[26].Articles);

        },
        onSubmit: function(e)
        {
            console.log("Searching for: " + this.form.keyword);
            e.preventDefault();

            this.searchApi.SearchArticles({ 'vcmsbundle_search' : this.form });

            this.submitted = true;

            return false;
        },

        onReset: function(){

        },
        OnSearchRequestFail : function (thrownError, xhr)
        {
            console.log("Search Failed");
            console.log(thrownError);
        },
        OnSearchRequestSuccess : function(result)
        {
            console.log("Search Success " + result.length + " records. ");
            
            this.items.length = 0;
            var alist = this.items;
            this.addArticles( alist, result.data);
        }

    }

});

export default VueSearch;

</script>


<style>

.search_app_title { 
    font-size: 1em;
    color : maroon;
    margin-left: 0;
    border-style: solid;
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 1px;
    margin-bottom: 2em;
}

.search_app_footer { 
    font-size: 1em;
    color : maroon;
    margin-left: 0;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0;
    border-left-width: 0;
    border-bottom-width: 0;
    margin-top: 2em;
}


.results_table
{
    font-family: Roboto, sans-serif;
    font-size: .8em;
}
.search_form_class {
    font-family: Roboto, sans-serif;
    font-size: .8em;    
}
</style>
