<script>
import Vue from 'vue';

// const eventBlockData  = require('../../data/mainpage_events_data.gen.json');

var EventsBlock = Vue.component("vcms-events-block",
{
    props : {
        EventsData : Object,
    },
    data : function()
    {
        var eventBlockData = {
            show_title : true,
            events : [],
        }
        eventBlockData.events = this.EventsData.events.map(
                            function(e) { 
                                e.url = Routing.generate('event', { 'eventid' : e.eid });
                                e.isActive = false; // (e.eid == 74)
                                e.showTitleImage = (e.titleimage_path!=null) && (e.titleimage_path.length >0  )
                            return e; }
                            );

        return eventBlockData;
    },
    watch: {
        EventsData : function(){
                   
        }
    }
    

});

export default EventsBlock;

</script>


<template>


<div id="vue_eventlist">

    <div v-if="show_title">
            <div align="center" class="frontpage_section_header mt-1 mb-3"> News and Events </div>
    </div>

    <b-list-group class="vcms_fp_events">

            <b-list-group-item class="mb-2 bg-white border-default p-2 pt-1 pb-4 shadow-light" v-for="event in events" v-bind:key="event.eid" type="none">
                <img v-if="event.showTitleImage"  v-bind:src="event.titleimage_path" img-responsive style="width:100%;">
                <a v-bind:href="event.url" v-bind:class="{ event_item_active: event.isActive }" class="stretched-link" style="font-weight:medium">
                    {{ event.title }}  </a>
                <span v-if="event.subtitle!='.'"> {{ event.subtitle }} </span>
            </b-list-group-item>

    </b-list-group>    

</div>


</template>


<style>
.event_item_active
{
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    color: maroon;
}

.vcms_fp_events
{
	font-family: Arial, Helvetica, sans-serif;
	margin-left:0;
	padding-left:0;

	border-width: 1px 1px 1px 1px;	
    border-color: red;
    font-size: .8em;
}
.vcms_fp_events ul
{
	padding-left:.5em;
}
.vcms_fp_events li
{
	font-family: Georgia, 'Times New Roman', Times, serif;
	font-size: .9em;
	margin-top:1em;
	padding-bottom: .4em;
	
	border-style: solid;
	border-color: lightgray;
	border-width: 0 0 1px 0;	
}

.vcms_fp_events a
{
	display: block;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	font-size:1.1em;
	font-weight: normal;
	margin-top: 1em;	
	margin-bottom: .4em;	
}
</style>
