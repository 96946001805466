
import '../css/app.css';
import '../css/social_buttons.css';

require ('../scss/main.scss');
import '../css/pijsite3.css';

import 'font-awesome/css/font-awesome.min.css';

import VcmsUsers from './vcmsusers.js';

// import '../social-share-kit/dist/css/social-share-kit.css'
// import SocialShareKit from '../social-share-kit/dist/js/social-share-kit.js'

import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

// import JournalSlides from '../vue/journal_slides/journal_slide.js'
import VueSearch from '../vue/search_page/search_page.vue'
import VcmsVueApp from '../vue/vcms.js'
import CMSVideoPlayer from './video/videoplayer';
import VcmsShopCart from '../vue/vcms_shop/vcms_cart.vue';


// import vcms_pp from './paypal/pppij.js'

const vcms_routes = require('../data/vcms_routes.json');
const Routing = require('../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js');

window.Routing = Routing;
window.vcms_routes = vcms_routes;


$(document).ready(function() 
{
    // var sitemnu = new Vue({
    //     template: '<pijvue-site-menu ></pijvue-site-menu>',
    //     components: { SiteMenu },
    // }).$mount('#pijvue_sitemenu');

    Routing.setRoutingData(vcms_routes);

    var formOptions = document.querySelector('#cmsform_opts');
    var cmsOptions = JSON.parse( formOptions.dataset.cmsOptions );

    console.log("Vcms::" + cmsOptions.contenttype + " :: " + cmsOptions.contentid );

 /*
   SocialShareKit.init({
        onBeforeOpen: function(targetElement, network, paramsObj){
            console.log("bo");
        },
        onOpen: function(targetElement, network, networkUrl, popupWindow){
            console.log("oo");
        },
        onClose: function(targetElement, network, networkUrl, popupWindow){
            console.log("oc");
        }
    });
*/
    var cms_search_opts = document.querySelector('#cms_search_kw');
    var search_kword = '';
    if (cms_search_opts != null)
        search_kword = JSON.parse( cms_search_opts.dataset.preload_keyword );

    var CmsClient = new VcmsUsers();
    CmsClient.InitUser();

    if (cmsOptions.contenttype=="home")
    { 
        // var fpslides = new JournalSlides();    
        // fpslides.setupCarousel();
    }
    else
    if (cmsOptions.contenttype=="aboutpij")
    {
        // VcmsVueApp.render('#full_site_page');
    }
    if (cmsOptions.contenttype=="vhome" || cmsOptions.contenttype=="blogs" || cmsOptions.contenttype=="journals" || cmsOptions.contenttype=="authors" )
    { 
        VcmsVueApp.render('#full_site_page');
    }    
    else
    if (cmsOptions.contenttype == "userprofile")
    {
        $('#tabs-buttons').tabs();

        CmsClient.InitProfilePic();
    }
    else
    if (cmsOptions.contenttype=="search")
    {           
        // cmsSearch.InitSiteSearch(search_kword);
        var srch = new Vue({
            template: '<vcms-content-search/>',
            components: { VueSearch },
        }).$mount('#search_page_div');
    }
    else
    if (cmsOptions.contenttype == "journal")
    {
        // var ppjournal = new vcms_pp(cmsOptions);
        // ppjournal.InitPPJournal();
    }
    else
    if ( cmsOptions.contenttype == "registration" )
    {
        // CmsClient.InitRegistrationValidation();
    }
    if ( (cmsOptions.contenttype == "store")||(cmsOptions.contenttype == "article"))
    {        
        VcmsVueApp.render('#full_site_page', CmsClient);
    }
    if ( cmsOptions.contenttype == "video" ) {
        console.log("Initializing Video");
        var vplayer = new CMSVideoPlayer();
        vplayer.InitPlayer("my-player");
    
    }

    
    // console.log("content-type: " +  cmsOptions.contenttype)
    // console.log("Initializing SSK");
    // console.log( $('.ssk') );
    // $('.ssk').forEach(function (e)
    // {
    //     console.log(e);
    // });
    
    window.SocialShareKit.init();
    // check for media 
    $( 'figure.media' ).removeClass('media');
    $( 'figure.media' ).addClass('ck_media');

});
